.featured-news {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
}

.featured-news .news {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.news img {
  width: 500px;
}

/* .news .news-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: calc(100% / 4 - 15px);
  text-align: center;
}

.news-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.news-item a {
  color: green;
} */

@media screen and (max-width: 800px) {
  .featured-news .news {
    flex-direction: column;
    width: 100%;
  }

  .news img {
    width: 100%;
  }
}
