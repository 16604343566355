.about {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 20px;
}

.about img {
    width: 400px;
}

.about .about-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
}

@media screen and (max-width: 800px) {
    .about {
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
    }

    .about .about-content {
        align-items: center;
        justify-content: center;
    }
}