header {
    background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url('../images/hero-img.jpg') no-repeat center/cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 130px 30px;
}

header h1 {
    font-size: 50px;
    text-align: center;
    white-space: pre-line
}

@media screen and (max-width: 685px) {
    header h1 {
        font-size: 30px;
    }
}