@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Poppins';
  transition: .3s all ease-in-out;
}

a {
  color: #000;
}

a:hover, a.active {
  color: green;
}

.page {
  margin-top: 80px;
}

.error {
  color: #721c24;
  background: #f8d7da;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.success {
  color: green;
  background: greenyellow;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.r-btn {
  padding: 10px 20px;
  background: green;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}