.gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  padding: 30px;
}

.gallery h1 {
  text-align: center;
}

.gallery .gallery-division {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
}

.gallery-division .gallery-divs {
  width: calc(100%/3 - 30px);
}

.gallery-divs img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.gallery-divs a {
  color: green;
}

.gallery-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 100px;
}

.gallery-items .gallery-item {
  width: calc(100% / 3 - 30px);
  height: 350px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 900px) {
  .gallery-items .gallery-item {
    width: calc(100% / 2 - 20px);
    padding: 20px;
  }
}

@media screen and (max-width: 650px) {
  .gallery-items .gallery-item {
    width: 100%;
    padding: 20px;
  }
}
