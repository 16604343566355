.rts {
    padding: 40px;
}

.rts img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin-bottom: 20px;
}

.rts ol {
    list-style-type: lower-roman;
}