.team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  padding: 30px;
}

.team h1 {
  text-align: center;
}

.team .team-members {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.team-members .member {
  width: calc(100% / 3 - 20px);
}

.member img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 900px) {
  .team-members .member {
    width: calc(100% / 2 - 20px);
  }
}

@media screen and (max-width: 650px) {
  .team-members .member {
    width: 100%;
  }
}
