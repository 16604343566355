.contact-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
}

.contact-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    flex-basis: 45%;
}

.contact-info .info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.contact-form {
    padding: 20px;
    flex-basis: 45%;
}

@media screen and (max-width: 660px) {
    .contact-info, .contact-form {
        flex-basis: 100%;
    }
}