nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-right: 20px;
    padding-left: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 10px #ebebeb;
}

nav img {
    width: 100px;
}

nav ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    transition: .5s;
    list-style: none;
}

nav ul.show {
    right: 20px;
}

nav ul li a {
    text-transform: uppercase;
}

nav .menu-bar {
    font-size: 30px;
    cursor: pointer;
    margin-right: 20px;
    display: none;
}

@media screen and (max-width: 980px) {
    nav ul {
        position: fixed;
        top: 120px;
        right: -100%;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        transition: .5s;
        background: #fff;
        padding: 40px;
        border-radius: 10px;
        box-shadow: 0 0 10px #ccc;
    }

    nav .menu-bar {
        display: block;
    }
}