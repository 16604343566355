.paystack-button {
    background-color: green;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
}

.modal-box {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65));
}

.modal-box .modal {
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    text-align: center;
    width: 400px;
}

.modal .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
    border: 2px solid yellow;
    border-radius: 50%;
    font-size: 30px;
}

.modal-box .close {
    position: absolute;
    top: 20px;
    right: 40px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
}