.vision {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 40px;
}

.vision img {
    width: 500px;
    height: 250px;
}

@media screen and (max-width: 800px) {
    .vision {
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
    }

    .vision img {
        width: 100%;
    }
}