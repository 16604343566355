footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    gap: 20px;
    background: green;
    color: #fff;
}

footer .social-media {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.footer .footer-link {
    color: #fff;
    background: #09095f;
}

footer .social-media a {
    color: #fff;
    transition: .3s all;
}

footer .social-media a:hover {
    color: #09095f;
}

@media screen and (max-width: 700px) {
    footer {
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    footer {
        justify-content: center;
    }
}