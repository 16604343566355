.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.contact-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.contact-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.location, .email, .call {
    text-align: center;
    margin-bottom: 15px;
}

form {
    margin-top: 20px;
}

.input-box {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input, textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea {
    max-width: 100%;
    height: 150px;
}

input[type="submit"] {
    background-color: green;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    .contact-details {
        flex-direction: column;
    }

    .location, .email, .call {
        width: 100%;
    }
}